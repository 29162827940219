// Path to file C:\Madhav\DotOn\src\components\Header\Header.js

import React, { useState } from 'react'; // Importing useState to manage state
import './header.css'; // Importing the CSS for styling
import { Link } from 'react-router-dom'; // Importing Link for navigation

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false); // State to control menu visibility

  // Function to toggle menu visibility
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen); // Toggle the menu open/close
  };

  return (
    <header className="header">
       <Link to="/" > DotOn Properties</Link>

      {/* Hamburger menu icon for smaller screens */}
      <div className="header-menu-icon" onClick={toggleMenu}>
        ☰ 
      </div>

      {/* Navigation links */}
      <nav className={`header-nav ${isMenuOpen ? 'expanded' : ''}`}>
        <Link to="/" onClick={toggleMenu}>Home</Link> {/* Close menu when a link is clicked */}
        <Link to="/Sell-Rent-Your-Property" onClick={toggleMenu}>Sell/Rent Property</Link>
        <Link to="/contact-us" onClick={toggleMenu}>Contact US</Link>
      </nav>
    </header>
  );
};

export default Header;
