//C:\Madhav\DotOn\src\components\PropertyList\PropertyList.js

import React, { useState, useEffect  } from 'react';
import PropertyItem from '../PropertyItem/PropertyItem';
import './propertylist.css'; // Assuming you'll have a CSS file for styling
import properties from '../../data/properties.json';
import BHKFilter from '../Filter/BHKFilter';
import FurnishingFilter from '../Filter/FurnishingFilter'; // Import the FurnishingFilter component
import BuyOrRentFilter from '../Filter/BuyOrRentFilter';


const PropertyList = () => {

    const [selectedBhks, setSelectedBhks] = useState(new Set());
    const [selectedFurnishing, setSelectedFurnishing] = useState(new Set()); // State for furnishing options
    const [selectedBuyRent, setSelectedBuyRent] = useState(new Set());

    // new code
    const [activeFilter, setActiveFilter] = useState(null);

    const handleOutsideClick = (event) => {
        const filtersContainer = document.querySelector('.filters');
        if (filtersContainer && !filtersContainer.contains(event.target)) {
          setActiveFilter(null); // Close all filters
        }
      };
    
      useEffect(() => {
        // Add event listener for outside clicks
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick); // Cleanup on unmount
        };
      }, []);
    
      const toggleFilter = (filterName) => {
        setActiveFilter((prevActiveFilter) => (prevActiveFilter === filterName ? null : filterName)); // Toggle active filter
      };

    // new code


    const handleBhkFilterChange = (bhk, isChecked) => {
        setSelectedBhks((prevSelectedBhks) => {
            const newSelectedBhks = new Set(prevSelectedBhks);
            if (isChecked) {
                newSelectedBhks.add(bhk);
            } else {
                newSelectedBhks.delete(bhk);
            }
            return newSelectedBhks;
        });
    };

    const handleFurnishingFilterChange = (furnishing, isChecked) => {
        setSelectedFurnishing((prevSelectedFurnishing) => {
            const newSelectedFurnishing = new Set(prevSelectedFurnishing);
            if (isChecked) {
                newSelectedFurnishing.add(furnishing);
            } else {
                newSelectedFurnishing.delete(furnishing);
            }
            return newSelectedFurnishing;
        });
    };

    const handleBuyRentFilterChange = (status, isChecked) => {
        setSelectedBuyRent((prevSelectedBuyRent) => {
            const newSelectedBuyRent = new Set(prevSelectedBuyRent);
            if (isChecked) {
                newSelectedBuyRent.add(status);
            } else {
                newSelectedBuyRent.delete(status);
            }
            return newSelectedBuyRent;
        });
    };

    const filteredProperties = properties.filter(
        (property) => (selectedBhks.size === 0 || selectedBhks.has(property.bedrooms)) &&
                       (selectedFurnishing.size === 0 || selectedFurnishing.has(property.furnishingStatus))&&
                       (selectedBuyRent.size === 0 || selectedBuyRent.has(property.status))
    );
    
    return (
        <div className="property-list-container">
            <div className="filters-wrapper">
                <div className="filters">
                    <BHKFilter isActive={activeFilter === 'BHK'} toggleFilter={() => toggleFilter('BHK')} onFilterChange={handleBhkFilterChange} />
                    <FurnishingFilter isActive={activeFilter === 'Furnishing'} toggleFilter={() => toggleFilter('Furnishing')} onFilterChange={handleFurnishingFilterChange} />
                    <BuyOrRentFilter isActive={activeFilter === 'BuyOrRent'} toggleFilter={() => toggleFilter('BuyOrRent')} onFilterChange={handleBuyRentFilterChange} />
                </div>
            </div>
            <div className="property-list">
                {filteredProperties.map((property) => (
                    <PropertyItem key={property.id} property={property} />
                ))}
            </div>
        </div>
    );
};

export default PropertyList;
