/* Path to file C:\Madhav\DotOn\src\components\PropertyDetail\PropertyDetail.js */

import React, { useState, useEffect } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import properties from '../../data/properties.json'; 
import commercialProperties from '../../data/commercialProperties.json'; 
import { Swiper, SwiperSlide } from 'swiper/react'; 
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

// import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import './PropertyDetail.css'; 
import {FaShare,FaFileContract, FaMoneyCheck, FaDoorClosed, FaArrowCircleUp,FaBuilding, FaRulerCombined, FaStreetView, FaCalendarAlt, FaCouch, FaMoneyBillWave,FaCar,FaHome } from 'react-icons/fa';

const PropertyDetail = () => {
  const { propertyId } = useParams();
  // Determine the property category
  const isCommercial = parseInt(propertyId, 10) > 999;
  const property = isCommercial ? commercialProperties.find(p => p.id.toString() === propertyId) : properties.find(p => p.id.toString() === propertyId)
  const location = useLocation();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const propertyUrl = window.location.origin +"/#"+location.pathname;
  const images = property.images;
  const [showMore, setShowMore] = useState(false);
  const truncatedDescription = property.description.substring(0, 250);
  const copyToClipboard = () => {
    
    navigator.clipboard.writeText(propertyUrl);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 1000); // Hide tooltip after 2 seconds
  };

  if (!property) {
    return <div>Property not found.</div>;
  }


  
  return (
    <div className="property-detail-container">
      <div className="property-basics">
      <div className="propert-title">
        <h3>{property.title}  </h3>
        <button onClick={copyToClipboard} className="share-button" title="share property">
          <FaShare /> Share
        </button>
        {tooltipVisible && (
          <div className="tooltip"> Link Copied to clipboard!</div>
        )}


      </div >
        <button className="contact-button" onClick={() => window.open("https://wa.me/9624490553?text=Hello,%20I%20would%20like%20to%20inquire%20about%20a%20property.", "_blank")}
        ><h2>Contact: 9624490553 </h2> </button>
      </div>


      {/* for smaller screen */}
      <div className="property-carousel">
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
          {property.images.map((url, index) => (
            <SwiperSlide key={index}>
              <img src={url} alt={`Property view ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {/* for smaller screen */}

      <div className="property-images-grid">
  {images.map((url, index) => (
    <div key={index} className="property-image-item">
      <img src={url} alt={`Property view ${index + 1}`} />
      

    </div>
  ))}
  
</div>
    <div className="property-info-container">
      <div className="property-info-title">
      <h2>Overview</h2>
      </div>
      <div className="property-info">
        <div className="property-detail-item">
        <FaBuilding className="icon" /><div className="property-detail-heading">Project Name</div>
        <div className="property-detail-value">{property.project}</div>
        </div>

        {/* <div className="property-detail-item">
        
        {isCommercial?<FaArrowCircleUp className="icon" />: <FaStreetView className="icon" />}
        {isCommercial?<div className="property-detail-heading">Floor</div>: <div className="property-detail-heading">Balcony</div>}
        {isCommercial?<div className="property-detail-value">{property.floor}</div>: <div className="property-detail-value">{property.balcony}</div>}
        
        </div> */}

        <div className="property-detail-item">
        <FaMoneyBillWave className="icon" /><div className="property-detail-heading">{property.status === "For Rent" ? 
          "Rent" :
          "Price"} </div>
        <div className="property-detail-value">{property.status === "For Rent" ? 
          `₹${property.price}/month` :
          `₹${property.price}`}</div>
        </div>

  

        {/* <div className="property-detail-item">
        <FaRulerCombined className="icon" /><div className="property-detail-heading">Super built-up Area</div>
        <div className="property-detail-value">{property.propertyArea} sqft</div>
        </div> */}

        <div className="property-detail-item">
        <FaCalendarAlt className="icon" /><div className="property-detail-heading">Available From</div>
        <div className="property-detail-value">{property.availble}</div>
        </div>
      
        <div className="property-detail-item">

        <FaCouch className="icon" /><div className="property-detail-heading">Furnishing</div>
        <div className="property-detail-value">{property.furnishingStatus}</div>
        </div>

        <div className="property-detail-item">

        <FaCar className="icon" /><div className="property-detail-heading">Alloted Parking</div>
        <div className="property-detail-value">{property.parking}</div>
        </div>

        <div className="property-detail-item">

        <FaHome className="icon" /><div className="property-detail-heading">Floor</div>
        <div className="property-detail-value">{property.floor}</div>
        </div>
        

        <div className="property-detail-item">
        {property.status === "For Rent" ? <FaMoneyCheck className="icon" />: <FaRulerCombined className="icon" />  }
        <div className="property-detail-heading">{property.status === "For Rent" ? 
          "Deposit" :
          "Super built-up Area"} </div>
        <div className="property-detail-value">{property.status === "For Rent" ? 
          `₹${property.deposit * property.price}` :
          `${property.propertyArea} sq.ft`}</div>
        </div>

        <div className="property-detail-item">
        {property.status === "For Rent" ? <FaFileContract className="icon" /> : <FaDoorClosed className="icon" />}
        <div className="property-detail-heading">{property.status === "For Rent" ? 
          "Lease For " :
          "Entrance Facing"}</div>
        <div className="property-detail-value">{property.status === "For Rent" ? 
          `${property.Lease}` :
          `${property.entraceFacing}`}</div>
        </div>
      </div>
      </div>
      <div className="property-info-container">
        <div className="property-info-title">
          <h2>Description</h2>
          <div className="property-desc-container">
            <div className="property-description-title">
              <h3>About Property:</h3>
            </div>
            <div className="property-description">
          {showMore ? (
            <p>{property.description}<span onClick={() => setShowMore(false)} className="show-less">
            show less
            </span></p>
          ) : (
            <p>{truncatedDescription}
            <span onClick={() => setShowMore(true)} className="show-more">
            show more
            </span>
            </p>
          )}
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default PropertyDetail;
