// ContactUs.js

import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import './ContactUs.css'; // Your CSS file for styling

const ContactUs = () => {
  // Dummy map src - replace with your actual location
  const mapSrc = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.8528762356023!2d72.53273557531615!3d23.102480979117864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e83611990bf99%3A0x93d2117cdd928245!2sDotOn%20Properties%20Brokers%20%26%20Services!5e0!3m2!1sen!2sin!4v1713289698950!5m2!1sen!2sin";

  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <p>At DotOn Properties, we value our relationships with clients and partners. Reach out to us anytime.</p>
      
      <div className="contact-details">
        <h2>Get in Touch</h2>
        <p>Phone: +91 9624490553</p>
        <p>Email: support@doton.in</p>
        <p>Address: 107, Shaligram Square, Near Olive Greens, Gota, Ahmedabad</p>

        <div className="social-media-links">
          <FaFacebook className="icon" />
          <FaTwitter className="icon" />
          <FaInstagram className="icon" />
          <FaLinkedin className="icon" />
        </div>
      </div>

      <div className="map-container">
        <h2>Our Location</h2>
        <iframe
          title="company-location"
          src={mapSrc}
          width="600"
          height="450"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
