// C:\Madhav\DotOn\src\components\Home\Home.js

import React from 'react';
import PropertyList from '../PropertyList/PropertyList';
import Tabs from '../Tabs/Tabs';
import './Home.css'


const Home = () => {
    return (
      <div className="home">
        <Tabs />
        <footer className="footer-section">
        <p>DotOn Properties | Your property our priority</p>
      </footer>
      </div>
    );
  };
  
  export default Home;