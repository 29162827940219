

import React from 'react';
import './BuyOrRentFilter.css';

const BuyOrRentFilter = ({ isActive, toggleFilter, onFilterChange }) => {
  const buyOrRentOptions = ["Buy", "Rent"]; // Buy or Rent options
    const statusMapping = {
    "Buy": "For Sell",
    "Rent": "For Rent"
  };

  return (
    <div className={`buy-or-rent-filter-wrapper ${isActive ? 'active' : ''}`}>
      <button className="buy-or-rent-filter-button" onClick={toggleFilter}>
        Buy/Rent <span className={`arrow ${isActive ? 'down' : 'up'}`}>▼</span>
      </button>
      
        <div className="buy-or-rent-options">
          {buyOrRentOptions.map((option, index) => (
            <React.Fragment key={index}>
              <input
                id={`buyOrRent${option}`}
                type="checkbox"
                value={option}
                onChange={(e) => onFilterChange(statusMapping[option], e.target.checked)}
              />
              <label htmlFor={`buyOrRent${option}`} className="buy-or-rent-option-label">
                {option}
              </label>
            </React.Fragment>
          ))}
        </div>
    </div>
  );
};

export default BuyOrRentFilter;
