
import React from 'react';
import './BHKFilter.css';

const BhkFilter = ({ isActive, toggleFilter, onFilterChange }) => {
  const bhkOptions = [1, 2, 3, 4]; // BHK options

  return (
    <div className={`bhk-filter-wrapper ${isActive ? 'active' : ''}`}>
      <button className="bhk-filter-button" onClick={toggleFilter}>
        BHK <span className={`arrow ${isActive ? 'down' : 'up'}`}>▼</span>
      </button>
      
        <div className="bhk-options">
          {bhkOptions.map((bhk, index) => (
            <React.Fragment key={index}>
              <input
                id={`bhk${bhk}`}
                type="checkbox"
                value={bhk}
                onChange={(e) => onFilterChange(bhk, e.target.checked)}
              />
              <label htmlFor={`bhk${bhk}`} className="bhk-option-label">
                {bhk}BHK
              </label>
            </React.Fragment>
          ))}
        </div>

    </div>
  );
};

export default BhkFilter;
