// PropertyPartnerships.js

import React from 'react';
import './PropertyPartnerships.css'; // Your CSS file for styling
import { IoCallSharp, IoImagesSharp, IoPeopleSharp, IoSearchSharp,IoLogoWhatsapp } from 'react-icons/io5';

const PropertyPartnerships = () => {
  return (
    <div className="property-partnerships">
      <header className="header-section">
        <h1>Want to Sell/Rent your property?</h1>
        <p>Join us to maximize your property's potential, whether you're looking to sell or rent.</p>
      </header>

      <section className="services-offered">
        <h2>Our Services</h2>
        <p>We provide comprehensive support for property owners and investors in Ahmedabad to navigate the real estate market with ease.</p>
        {/* List services here */}
      </section>


<section className="how-it-works">
  <h2>How It Works</h2>
  <div className="steps-container">
    <div className="step-item">
      <IoCallSharp className="icon" />
      <p>Connect with us on WhatsApp/Call <h3> 9624490553</h3> </p>
      
    </div>
    <div className="step-item">
      <IoImagesSharp className="icon" />
      <p>Provide Property photos and other details.</p>
    </div>
    <div className="step-item">
      <IoPeopleSharp className="icon" />
      <p>Provide your requirements for Tenants/Buyers.</p>
    </div>
    <div className="step-item">
      <IoSearchSharp className="icon" />
      <p>We will find you the perfect match for your properties.</p>
    </div>
  </div>
</section>


      <section className="market-analysis">
        <h2>Market Insights</h2>
        <p>Stay informed with the latest trends in real estate to make data-driven decisions.</p>
        {/* Include a downloadable market report here */}
      </section>


      <section className="contact-info">
        <h2>Get In Touch</h2>
        <p>Have questions or need assistance? Contact us directly or schedule a consultation.</p>
        <button onClick={() => window.open("https://wa.me/9624490553?text=Hello,%20I%20would%20like%20to%20talk%20about%20my%20property.", "_blank")}><h2>Contact: 9624490553 </h2> </button>
      </section>

      <footer className="footer-section">
        <p>DotOn Properties | Your property our priority</p>
      </footer>
    </div>
  );
};

export default PropertyPartnerships;
