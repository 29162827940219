// C:\Madhav\DotOn\src\components\PropertyItem\PropertyItem.js

import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import './propertyitem.css'; // Assuming you'll have a CSS file for styling

const PropertyItem = ({ property }) => {

    const imagePath = process.env.PUBLIC_URL + property.imageUrl;

    return (
        <div className="property-item">
            <Link to={`/properties/${property.id}`}> 
            <div className={`verified-badge ${property.badge === "verified" ? "" : "Unverified"}`}>
                        verified
                    </div>
            <img src={imagePath} alt={property.title} />
            
            <div className="property-info-overview">
             <h3>{property.title}</h3>
                <p>₹{property.price}</p>
                <p>{property.furnishingStatus}</p>
                

            </div>
            </Link>
        </div>  
    );
};

export default PropertyItem;
