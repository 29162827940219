//C:\Madhav\DotOn\src\components\PropertyList\CommercialPropertyList.js

import React, { useState, useEffect  } from 'react';
import PropertyItem from '../PropertyItem/PropertyItem';
import './commercialPropertyList.css'
import properties from '../../data/commercialProperties.json';
import FurnishingFilter from '../Filter/FurnishingFilter';
import BuyOrRentFilter from '../Filter/BuyOrRentFilter';
import CommercialPropertyTypeFilter from '../Filter/CommercialPropertyTypeFilter'

const CommercialpropertyList = () => {

    const [selectedFurnishing, setSelectedFurnishing] = useState(new Set()); // State for furnishing options
    const [selectedBuyRent, setSelectedBuyRent] = useState(new Set());
    const [selectedPropertyType, setSelectedPropertyType] = useState(new Set());

    // new code
    const [activeFilter, setActiveFilter] = useState(null);

    const handleOutsideClick = (event) => {
        const filtersContainer = document.querySelector('.filters');
        if (filtersContainer && !filtersContainer.contains(event.target)) {
          setActiveFilter(null); // Close all filters
        }
      };
    
      useEffect(() => {
        // Add event listener for outside clicks
        document.addEventListener('click', handleOutsideClick);
        return () => {
          document.removeEventListener('click', handleOutsideClick); // Cleanup on unmount
        };
      }, []);
    
      const toggleFilter = (filterName) => {
        setActiveFilter((prevActiveFilter) => (prevActiveFilter === filterName ? null : filterName)); // Toggle active filter
      };


    const handleFurnishingFilterChange = (furnishing, isChecked) => {
        setSelectedFurnishing((prevSelectedFurnishing) => {
            const newSelectedFurnishing = new Set(prevSelectedFurnishing);
            if (isChecked) {
                newSelectedFurnishing.add(furnishing);
            } else {
                newSelectedFurnishing.delete(furnishing);
            }
            return newSelectedFurnishing;
        });
    };

    const handleBuyRentFilterChange = (status, isChecked) => {
        setSelectedBuyRent((prevSelectedBuyRent) => {
            const newSelectedBuyRent = new Set(prevSelectedBuyRent);
            if (isChecked) {
                newSelectedBuyRent.add(status);
            } else {
                newSelectedBuyRent.delete(status);
            }
            return newSelectedBuyRent;
        });
    };

    const handlePropertyTypeFilterChange = (type, isChecked) => {
        setSelectedPropertyType((prevSelectedPropertyType) => {
            const newSelectedPropertyType = new Set(prevSelectedPropertyType);
            if (isChecked) {
                newSelectedPropertyType.add(type);
            } else {
                newSelectedPropertyType.delete(type);
            }
            return newSelectedPropertyType;
        });
    };

    const filteredProperties = properties.filter(
        (property) =>  (selectedFurnishing.size === 0 || selectedFurnishing.has(property.furnishingStatus))&&
                       (selectedBuyRent.size === 0 || selectedBuyRent.has(property.status)) && (selectedPropertyType.size === 0 || selectedPropertyType.has(property.propertyType))
    );
    return (
        <div className="commercial-property-list-container">
            <div className="filters-wrapper">
                <div className="filters">
                <CommercialPropertyTypeFilter isActive={activeFilter === 'PropertyType'} toggleFilter={() => toggleFilter('PropertyType')} onFilterChange={handlePropertyTypeFilterChange} />  
                    <FurnishingFilter isActive={activeFilter === 'Furnishing'} toggleFilter={() => toggleFilter('Furnishing')} onFilterChange={handleFurnishingFilterChange} />
                    <BuyOrRentFilter isActive={activeFilter === 'BuyOrRent'} toggleFilter={() => toggleFilter('BuyOrRent')} onFilterChange={handleBuyRentFilterChange} />
              
                </div>
            </div>
        <div className="commercial-property-list">
            {filteredProperties.map((property) => (
                <PropertyItem key={property.id} property={property} />
            ))}
        </div>
        </div>
    );
};

export default CommercialpropertyList;
