import './App.css';
import React from 'react';
import Header from './components/Header/Header';
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ContactUs from './components/ContactUs/ContactUs';
import Home from './components/Home/Home';
import PropertyDetail from './components/PropertyDetail/PropertyDetail';
import PropertyPartnerships from './components/PropertyPartnerships/PropertyPartnerships'

function App() {


  return (
    <Router>
    <div className="App">
        <Header/>
      <div className="main-content">
        
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/properties/:propertyId" element={<PropertyDetail />} />
          <Route path="/Sell-Rent-Your-Property" element={<PropertyPartnerships />} />

        </Routes>
        
        
      </div>
    </div>
    </Router>
  );
}

export default App;
