import React, { useState } from 'react';
import './tabs.css';
import PropertyList from '../PropertyList/PropertyList';
import CommercialpropertyList from '../PropertyList/CommercialPropertyList';


const Tabs = () => {
    const [currentTab, setCurrentTab] = useState('1');

    const tabs = [
        { id: '1', tabTitle: 'Residential' },
        { id: '2', tabTitle: 'Commercial' },
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    return (
        <div className='container'>
            <div className='tabs'>
                {tabs.map((tab) =>
                    <button key={tab.id} id={tab.id} disabled={currentTab === tab.id} onClick={handleTabClick}>
                        {tab.tabTitle}
                    </button>
                )}
            </div>
            <div className='content'>
                <div className="Tabproperties">
                {currentTab === '1' ? <PropertyList/> : <CommercialpropertyList/>}
                </div> 
            </div>
        </div>
    );
}

export default Tabs;
