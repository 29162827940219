

import React from 'react';
import './CommercialPropertyTypeFilter.css';

const BuyOrRentFilter = ({ isActive, toggleFilter, onFilterChange }) => {
  const propertyTypeOptions = ["Shop", "Office", "Warehouse"]; 

  return (
    <div className={`property-type-filter-wrapper ${isActive ? 'active' : ''}`}>
      <button className="property-type-filter-button" onClick={toggleFilter}>
        PropertyType <span className={`arrow ${isActive ? 'down' : 'up'}`}>▼</span>
      </button>
     
        <div className="property-type-options">
          {propertyTypeOptions.map((option, index) => (
            <React.Fragment key={index}>
              <input
                id={`propertyType${option}`}
                type="checkbox"
                value={option}
                onChange={(e) => onFilterChange(option, e.target.checked)}
              />
              <label htmlFor={`propertyType${option}`} className="property-type-option-label">
                {option}
              </label>
            </React.Fragment>
          ))}
        </div>

    </div>
  );
};

export default BuyOrRentFilter;
