
import React from 'react';
import './FurnishingFilter.css';

const FurnishingFilter = ({ isActive, toggleFilter, onFilterChange }) => {
  const furnishingOptions = ["Fully Furnished", "Semi Furnished", "Unfurnished"]; // Furnishing options

  return (
    <div className={`furnishing-filter-wrapper ${isActive ? 'active' : ''}`}>
      <button className="furnishing-filter-button" onClick={toggleFilter}>
        Furnishing <span className={`arrow ${isActive ? 'down' : 'up'}`}>▼</span>
      </button>

        <div className="furnishing-options">
          {furnishingOptions.map((option, index) => (
            <React.Fragment key={index}>
              <input
                id={`furnishing${option}`}
                type="checkbox"
                value={option}
                onChange={(e) => onFilterChange(option, e.target.checked)}
              />
              <label htmlFor={`furnishing${option}`} className="furnishing-option-label">
                {option}
              </label>
            </React.Fragment>
          ))}
        </div>

    </div>
  );
};

export default FurnishingFilter;
